import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"

import Quiz from "../Quiz/Quiz"
import Bettina from "../images/bettina"
import Timo from "../images/timo"
import Igor from "../images/igor"
import Helena from "../images/Helena"
import Ulrich from "../images/Ulrich"

function Content({ newsletterInView, topInView }) {
  const [wiesoRef, wiesoInView] = useInView()
  const [spendenRef, spendenInView] = useInView()
  const [quizRef, quizInView] = useInView()

  useEffect(() => {
    window.addEventListener("keydown", handleFirstTab)
  })

  /* useEffect(() => {
    if (typeof window !== undefined) {
            if (topInView === true) {
        window.location.hash = "#top"
      }
      if (newsletterInView === true) {
        window.location.hash = "#newsLetter"
      }
      if (wiesoInView === true) {
        window.location.hash = "#wieso"
      }
      if (spendenInView === true) {
        window.location.hash = "#spenden"
      } 
      if (quizInView === true) {
        window.location.hash = "#quiz"
      }
    }
  })*/

  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add("user-is-tabbing")
      window.removeEventListener("keydown", handleFirstTab)
    }
  }

  return (
    <content className="content">
      <div className="textbox-trapezoid textbox-light text-center position-relative trapezoid-margin bg-primary weight-900">
        <div className="orange-trapezoid-top bg-primary z-index-0"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-2">
              Wir alle haben <span className="block-dark">Vorurteile</span>, die
              aber gar nicht der Realität entsprechen. Oder?{" "}
              <span className="block-dark">Faktencheck</span>
            </div>
          </div>
        </div>
        <div className="orange-trapezoid-bottom bg-primary"></div>
      </div>
      <section id="testimonials">
        <div className="container mb-4">
          <div className="trapezoid-spacer-bottom"></div>
          <div className="d-md-flex row w-100 justify-content-around">
            <div className="col-md-5">
              <h2 className="header-testimonial">
                Wer sich anstrengt, findet auch eine Arbeit?
              </h2>
              <p className="quote-testimonial font-size-default">
                Lange habe ich Vollzeit meine kranken Eltern gepflegt. Seit ein
                paar Jahren suche ich wieder Arbeit und habe hunderte
                Bewerbungen geschrieben - erfolglos. Ich habe gehört, so geht es
                vielen ab fünfzig
              </p>
            </div>
            <div className="col-md-5 mb-3">
              <div className="image-testimonial mx-auto">
                <Bettina />
              </div>
              <div className="name-testimonial font-size-default">
                <span>Bettina (62), </span>
                <span className="weight-900">arbeitssuchend</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container bg-dark text-center text-light font-size-default weight-400 py-3">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <p className="py-4 px-1 p-lg-4 mb-0">
                Ein paar Jahre zu alt? Alleinerziehend? Überqualifiziert? Alles
                Gründe für Ablehnung auf dem Arbeitsmarkt. Das sind die
                sogenannten{" "}
                <span className="weight-900">“Vermittlungshemmnisse”.</span>
              </p>
            </div>
          </div>
        </div>
        <div className="container my-4">
          <div className="row flex-row-reverse w-100 justify-content-between pt-4">
            <div className="col-md-5">
              <h2 className="header-testimonial">Leistung wird belohnt?</h2>
              <p className="quote-testimonial font-size-default">
                Meine Mutter ist seit Jahren auf Hartz 4 angewiesen. Ich würde
                gern mein eigenes Geld verdienen. Rasen mähen, am Fließband
                arbeiten, egal! Aber wenn ich Geld verdiene, bleibt mir davon
                kaum etwas übrig.
              </p>
            </div>
            <div className="col-md-5 mb-3">
              <div className="image-testimonial mx-auto">
                <Timo />
              </div>
              <div className="name-testimonial font-size-default">
                <span>Timo (18), </span>
                <span className="weight-900">Schüler</span>
              </div>
            </div>
          </div>
        </div>
        <div className="container bg-dark text-center text-light font-size-default weight-400 py-3">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1">
              <p className="py-4 px-1 p-lg-4 mb-0">
                <span className="weight-900">
                  Wer denkt denn da schon dran!?{" "}
                </span>
                Fast 2 Millionen Kinder und Jugendliche sind in Hartz 4! Wenn
                sie sich was dazuverdienen wollen oder eine Ausbildung machen,
                werden bei Beträgen über 100 € sofort 80% wieder abgezogen. Erst
                seit diesem Jahr dürfen Schüler*innen bis zu 2400 € aus
                Ferienjobs behalten.
              </p>
            </div>
          </div>
        </div>
        <div className="container font-size-default my-4">
          <div className="d-md-flex row justify-content-around pt-4">
            <div className="col-md-5">
              <h2 className="header-testimonial">
                Beim Jobcenter wird man kompetent vermittelt?{" "}
              </h2>
              <p className="quote-testimonial">
                Ich will eine echte Perspektive, ohne weiter zum Jobcenter gehen
                zu müssen. Die haben mich aber an eine Zeitarbeitsfirma
                vermittelt. Da arbeite ich jetzt in Teilzeit und das Geld reicht
                hinten und vorne nicht. Ich muss also weiter mit Hartz 4
                aufstocken. Lehne ich solche Jobs ab, werde ich sanktioniert.
              </p>
            </div>
            <div className="col-md-5 mb-3">
              <div className="image-testimonial mx-auto">
                <Igor />
              </div>
              <div className="name-testimonial ">
                <span>Igor (35), </span>
                <span className="weight-900">Zeitarbeiter</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container bg-dark text-center text-light font-size-default weight-400 py-3">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <p className="py-4 px-1 p-lg-4 mb-0">
              <span className="weight-900">Krass: </span>
              2019 führte jede 4. Arbeitsvermittlung in Zeitarbeit. Mehr als
              jede dritte Arbeitsaufnahme aus Hartz 4 heraus ist kürzer als 6
              Monate; weil es z.B. nur einen befristeten Job oder Leiharbeit
              gibt. Rund jede 2. Arbeitsaufnahme führt nicht aus dem Hartz
              4-Bezug heraus.
            </p>
          </div>
        </div>
      </div>
      <section className="container mb-4" id="quiz" ref={quizRef}>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <h2 className="text-center my-5 weight-900 font-size-large">
              Das sind doch nur Einzelfälle?
            </h2>
            <p className="font-size-default">
              Von wegen! Hartz 4 ist voll mit Mythen und Vorurteilen. Wie sieht
              es bei dir aus: Kennst du dich mit unserem Sozialsystem aus? Stell
              dein Wissen über Hartz und Vorurteile jetzt auf die Probe! 8
              Fragen, 8 Antworten, 8 Überraschungen?
            </p>
          </div>
        </div>
        <div>
          <Quiz />
        </div>
      </section>
      <div className="container-fluid bg-primary py-5">
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <div className="row">
              <div className="col-12">
                <h3 className="text-dark text-center weight-900 font-size-large">
                  Wir haben <span className="text-white">1000 Menschen</span> zu
                  Hartz 4 befragt und ...
                </h3>
              </div>
            </div>
            <div className="row text-center my-4 font-size-default">
              <div className="col-md-4">
                <div className="row mb-4">
                  <div className="col">
                    <span className="weight-900 stat-number">65%</span>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <span className="text-center weight-400">
                      glauben, dass alle die möchten, einen Job finden.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row mb-4">
                  <div className="col">
                    <span className="weight-900 stat-number">51%</span>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <span className="text-center weight-400">
                      glauben, dass Hartz-4-Beziehende nichts zu tun haben.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row mb-4">
                  <div className="col">
                    <span className="weight-900 stat-number">31%</span>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col">
                    <span className="text-center weight-400">
                      glauben, dass Hartz-4-Beziehende garnicht arbeiten wollen
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row font-size-default">
              <div className="col-12">
                <p>
                  Ergebnisse, die nicht weiter von der Realität entfernt sein
                  könnten. Es gibt etwa dreimal mehr registrierte Arbeitslose
                  als bei der Bundesagentur für Arbeit gemeldete offene Stellen.
                  Noch dazu kommt, dass von 5,8 Millionen Hartz-4-Beziehenden
                  gerade mal ein Viertel tatsächlich arbeitslos sind. Eine
                  Umfrage von 2013 hat übrigens ergeben, dass für 80% der
                  Leistungsbeziehenden Arbeit das Wichtigste im Leben ist.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="spenden" className="container-fluid mb-4" ref={spendenRef}>
        <div className="row">
          <div className="col-12 col-md-10 offset-md-1">
            <h2 className="text-center my-5 weight-900 font-size-large">
              Worum geht es uns?
            </h2>
            <p className="font-size-default">
              <span className="weight-900">Sanktionsfrei</span> und der{" "}
              <span className="weight-900">Paritätische Wohlfahrtsverband</span>{" "}
              haben sich zusammengetan. Gemeinsam fordern wir ein System, das
              absichert und nicht verunsichert! Ein System, das die Menschen
              unterstützt, ihnen Mut macht und eine menschenwürdige
              Grundsicherung garantiert.
            </p>
          </div>
        </div>
        <div className="row font-size-default">
          <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2">
            <div className="row my-4">
              <div className="col-8 col-md-4 mx-auto mb-5 mb-md-3">
                <div className="img-wrapper">
                  <div className="img-round w-75 mx-auto mb-3">
                    <Ulrich />
                  </div>
                  <div className="text-center">
                    <p>Ulrich Schneider,</p>
                    <p className="weight-900">
                      CEO Paritätische Wohlfahrtsverband
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-8 col-md-4 mx-auto">
                <div className="img-wrapper">
                  <div className="img-round w-75 mx-auto mb-3">
                    <Helena />
                  </div>
                  <div className="text-center">
                    <p>Helena Steinhaus,</p>
                    <p className="weight-900">Gründerin Sanktionsfrei e.V.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row font-size-default">
          <div className="col-12 col-md-10 offset-md-1">
            <p>
              Dazu gehört eine satte Erhöhung des Regelbedarfs, die vollständige
              Abschaffung von Sanktionen, die Sicherung von angemessenem
              Wohnraum, der Ausbau von Angeboten der Arbeitsförderung,
              Bürokratie-Abbau und Entstigmatisierung von Arbeitslosigkeit. Ein
              neues System?
            </p>
          </div>
        </div>
      </section>
      <div className="container-fluid bg-secondary font-size-default py-5">
        <div className="row ">
          <div className="col-12 col-md-10  offset-md-1 text-center">
            <h2 className="text-center weight-900 font-size-large mb-4">
              Unterstütze uns!
            </h2>
            <p>
              Teile diese Seite, um die Forderungen vom Paritätischen und
              Sanktionsfrei zu verbreiten und stärke 5,8 Millionen Menschen den
              Rücken.
            </p>
            <p>
              Sanktionsfrei macht Hartz 4 ein bisschen menschlicher. Unterstütze
              den Verein bei seiner Arbeit:
              <br />
              <a
                href="https://sanktionsfrei.de/support"
                className="weight-900 text-dark hover-light"
                target="_blank"
              >
                www.sanktionsfrei.de/support
              </a>
            </p>
          </div>
        </div>
      </div>
    </content>
  )
}

export default Content
